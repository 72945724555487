import React from 'react'
import ReactPlayer from 'react-player'

import Layout from '../../components/layout'
import OtherLinks from '../../components/otherLinks/otherLinks'
import LightBox from '../../components/lightbox/lightbox'

import overview from '../../images/products/HR Prozesse/Abacus HR.jpg'
import bewerbungsprozess from '../../images/products/HR Prozesse/abacus-hr-bewerbungsprozess.jpg'
import zeugnisprozess from '../../images/products/HR Prozesse/Abacus_Zeugnis_Prozess.png'
import mitarbeitergespraecheprozess from '../../images/products/HR Prozesse/Abacus_Mitarbeitergepräche_Prozess.png'

import bewerber1 from '../../images/products/HR Prozesse/Abacus Bewerberstamm.png'
import bewerber2 from '../../images/products/HR Prozesse/Abacus Bewerbercockpit.png'
import bewerber3 from '../../images/products/HR Prozesse/Abacus Bewerber aus Sicht des Portals.png'

import zeugnis1 from '../../images/products/HR Prozesse/HR Portal Meine Daten Arbeitszeugnisse.jpg'

import mag1 from '../../images/products/HR Prozesse/HR Portal Meine Daten Mitarbeitergespräche.jpg'

import dossier1 from '../../images/products/HR Prozesse/Abacus Dossier aus Sicht HR.png'
import dossier2 from '../../images/products/HR Prozesse/Abacus Dossier aus Sicht des Mitarbeitenden im Portal.png'
import dossier3 from '../../images/products/HR Prozesse/Abacus Dossier aus Sicht des Mitarbeitenden im Portal geöffnet.png'

import budget1 from '../../images/products/HR Prozesse/Abacus Budget Cockpit.png'
import budget2 from '../../images/products/HR Prozesse/Abacus Budget Cockpit 2.png'

const Prozessmanagement = () => (
    <Layout>
        <section id="prozessmanagement" className="section-wrapper">
            <header className="section-header">
                <h3>HR Prozesse</h3>
            </header>
            <div className="post">
                <h3>Einleitung</h3>
                <p>
                    Via HR Portal (MyAbacus) und/oder Handy sind die Mitarbeitenden einer Unternehmung mit Abacus HR verbunden. Nur so ist möglich medienbruchfreie
                    HR Prozesse durch das System kontrolliert und überwacht laufen zu lassen. Dabei handelt es sich entweder um Abacus Standard HR Prozesse oder
                    individuell für den Kunden konzipierte Prozesse innerhalb des Portals auf Basis der integrierten Workflowengine Abacus BPE.
                </p>
                <img src={overview} alt="Übersicht" width="100%" />
            </div>
            <div className="post">
                <h3>Rekrutierung</h3>
                <p>
                    Die besten Bewerber suchen sich heute das Unternehmen aus, in welchem sie arbeiten möchten. Deshalb ist es für Firmen wichtig, sich als attraktiver
                    Arbeitgeber zu präsentieren. Im Bewerberprozess erhält der Bewerber den ersten Eindruck eines Unternehmens. Dieser Prozess muss reibungslos, schnell
                    und effizient ablaufen.
                </p>
                <p>
                    Das neue Abacus-Bewerbermanagement begleitet Sie während des gesamten Rekrutierungsprozesses, unterstützt Sie dabei
                    optimal und hilft Ihnen, Ihren Bewerberprozess professionell zu gestalten. So finden Sie die passenden Mitarbeitenden,
                    die Ihr Unternehmen erfolgreich in die Zukunft führen.
                </p>
                <img src={bewerbungsprozess} alt="Bewerbungsprozess" width="100%" />

                <br /><br />

                <h4>Die Highlights des Abacus Bewerberprozesses</h4>
                <ul>
                    <li>
                        Dossiers können direkt mit den Abteilungsleitern ausgetauscht werden und die Kommunikation zwischen den involvierten Personen erfolgt
                        schnell und vertraulich innerhalb der Abacus Software. Zudem wird jeder Schritt dokumentiert.
                    </li>
                    <li>
                        Sämtliche Schritte im Rekrutierungsprozess werden automatisiert. Für Bestätigungen, Absagen oder Einladungen an die Bewerbenden können definierte
                        Vorlagen genutzt werden. Bewerbungsdossiers liegen nicht herum und schützenswerte Unterlagen sind tatsächlich geschützt. Somit ist der Datenschutz
                        gewährleistet.
                    </li>
                    <li>Spannende Bewerbungen sind dank der effizienten Sternebewertung auf einen Blick erkennbar und können bevorzugt behandelt werden.</li>
                    <li>Der gesamte Rekrutierungsprozess lässt sich individuell anpassen und auf den meisten mobilen Geräten bedienen.</li>
                </ul>
                <br />
                <LightBox images={bewerberImages} thumbnailHeight={90} />
            </div>
            <div className="post">
                <h3>Arbeitszeugnis erstellen</h3>
                <p>
                    Die integrierte Abacus Zeugnis-Software unterstützt Personalabteilungen und Linienverantwortliche bei der Erstellung und Verwaltung
                    von Arbeitszeugnissen. Durch standardisierte Prozesse und Workflows reduziert sich der Aufwand für die Erstellung markant und bietet
                    gleichzeitig immer die Möglichkeit zur Personalisierung. Weitere Vorteile bieten die Textbausteine, die arbeitsrechtkonform und in
                    mehreren Sprachen verfügbar sind, sowie eine einfache Benutzeroberfläche, die auf beliebigen Endgeräten zur Verfügung steht.
                    So entstehen mit wenigen Klicks, faire und nachvollziehbare Zeugnisse, die aber auch individuell angepasst werden können.
                </p>
                <img src={zeugnisprozess} alt="Zeugnisprozess" width="100%" />
                <br /><br />
                <h4>Die Highlights</h4>
                <ul>
                    <li>Einfache Erstellung von Arbeitsbestätigungen, Zwischen- und Schlusszeugnissen</li>
                    <li>Vollständige Integration ins MyAbacus Mitarbeiter-Portal</li>
                    <li>Die Kompetenzbewertung, bildet die Grundlage zur Generierung eines individuellen Zeugnisses</li>
                    <li>Über 600 Textbausteine in vier Sprachen, aber auch individuelle Anpassungen sind möglich</li>
                    <li>Rechtssicherheit, da das Zeugnis auf bewährten Standards basiert</li>
                    <li>Qualität durch standardisierte Prozesse, mit nachvollziehbaren Beurteilungskriterien und Textbausteinen</li>
                    <li>Gesetzeskonform nach Art. 330a OR</li>
                </ul>
                <br />
                <LightBox images={zeugnisImages} thumbnailHeight={90} />

                <div className="post section-with-vid" id="video">
                    <div className="vid">
                        <ReactPlayer url="https://youtu.be/kDL6cBbHLlw" controls={true} height="100%" width="100%" />
                    </div>
                </div>
            </div>

            <div className="post">
                <h3>Mitarbeitergespräche</h3>
                <p>
                    Mitarbeitergespräche bilden den Grundstein der Personalentwicklung. Sie fördern das Vertrauen zwischen Mitarbeitenden und Vorgesetzten und
                    helfen die Aufgaben und Ziele des Mitarbeitenden gemeinsam zu definieren. Die Abacus-Software für Mitarbeitergespräche unterstützt Sie während
                    des gesamten Prozesses. Administrative Aufgaben sind sinnvoll automatisiert, sodass mehr Zeit für das eigentliche Gespräch bleibt.
                </p>
                <p>
                    Beispielsweise werden die Beurteilungsformulare während des Gesprächs ausgefüllt und im digitalen Personaldossier abgelegt.
                    Durch vordefinierte Prozesse wird sichergestellt, dass die Gespräche einer individuell definierten Struktur folgen und somit vergleich-
                    und nachvollziehbar sind. So unterstützen Sie ihre Mitarbeitenden, die Personalgespräche professionell und ergebnisorientiert zu führen.
                </p>
                <img src={mitarbeitergespraecheprozess} alt="Mitarbeitergesprächeprozess" width="100%" />
                <br /><br />
                <h4>Die Highlights</h4>
                <ul>
                    <li>Vollständig integrierte Lösung im Mitarbeiterportal</li>
                    <li>Möglichkeit zum Führen von unterschiedlichen Gesprächsvorlagen und Gesprächsmodellen</li>
                    <li>und Gesprächsmodellen</li>
                    <li>Zentraler Kompetenzstamm für Mitarbeitergespräche und Zeugnisse</li>
                    <li>Für jede Unternehmensgrösse und für jeden Anspruch die passende Lösung</li>
                    <li>Das Modul Mitarbeitergespräche baut auf der Organisation auf</li>
                    <li>Ausdruck des Beurteilungsbogens</li>
                    <li>Dokumentenablage zum jeweiligen Gespräch</li>
                </ul>
                <br />
                <LightBox images={magImages} thumbnailHeight={90} />
                <div className="post section-with-vid" id="video2">
                    <div className="vid">
                        <ReactPlayer url="https://youtu.be/r2566ciPhC8" controls={true} height="100%" width="100%" />
                    </div>
                </div>
            </div>

            <div className="post">
                <h3>Personaldossier</h3>
                <p>
                    Zu den Mitarbeitenden gehörende Dokumente wie Lohnabrechnungen, Arbeitsverträge, Ausbildungsvereinbarungen usw. können in den elektronischen Dossiers
                    abgelegt und bei Bedarf abgerufen werden. Dank der Volltextindexierung aller Dokumente kann mit einer Suchmaschine nach Stichworten gesucht und das
                    gewünschte Dokument sofort gefunden werden.
                </p>
                <LightBox images={dossierImages} thumbnailHeight={90} />
            </div>

            <div className="post">
                <h3>Personalkostenbudgetierung</h3>
                <p>
                    Im Lohn- und Gehaltsbereich werden in der Praxis verschiedenste Budgetierungspraktiken angewendet: Komplizierte Einkommensveränderungen auf das Jahres-
                    oder Monatsende, die Planung und Bewilligung der Lohnkosten für das nächste Kalenderjahr, monatliche oder projektbezogene Budgetierung benötigter
                    Mitarbeitender mit entsprechender Lohnsumme usw. Auch das zeitnahe Erstellen mehrerer Budgetvarianten gewinnt zunehmend an Bedeutung.
                </p>
                <h4>Die Highlights</h4>
                <ul>
                    <li>
                        Budgetcockpit: Das Abrechnungsprogramm für die Budgetierung bietet eine detaillierte Übersicht über die abgerechneten Mitarbeitenden und Stellen.
                        Die Abrechnungsdaten werden ausführlich angezeigt. Einzelne Lohndaten und Stammdaten können flexibel übersteuert werden und eine Resultatübersicht
                        bietet eine sofortige Kontrolle über die Auswirkung von Anpassungen. Der Benutzer wird mit einem Assistenten und strukturierten Abläufen schrittweise
                        durch den Budgetprozess geführt.
                    </li>
                    <li>
                        Budgetvarianten: Durch verschiedene Budgetvarianten lassen sich mehrere voneinander unabhängige Budgets ausarbeiten, um diese zum Beispiel dem Management
                        vorzulegen. So kann ein Budget mit einer Personalaufstockung und ein zweites Budget mit einer Personalreduktion erstellt werden. Mit den Budgetkonstanten
                        steht ein weiteres Instrument zur Verfügung, mit dem Teuerungsausgleiche, Lohnerhöhungsprozentsätze, Prämien usw. direkt im Budgetprozess angepasst werden
                        können.
                    </li>
                    <li>
                        Budgetjournale: Mehrere Auswertungen erlauben eine effiziente Kontrolle und Dokumentation der budgetierten Daten. Dabei können verschiedene Ebenen wie
                        Mitarbeiter-, Kostenstellen, Kostenstellengruppen oder organisatorische Einheiten ausgewertet werden.
                    </li>
                    <li>
                        Rollendes Budget mit Forecast-Funktionen: Neben den Budgetvarianten stehen auch rollende Budgets für Forecasts zur Verfügung. Dies erlaubt zum Beispiel
                        quartalsweise einen Abgleich zwischen den Ist- Werten und dem Budget vorzunehmen. Entspricht das Ergebnis des Abgleichs nicht dem gewünschten Resultat,
                        können rechtzeitig Massnahmen getroffen und die restlichen Quartale entsprechend nachbudgetiert werden. Das Originalbudget wird davon nicht beeinflusst.
                    </li>
                </ul>
                <br />
                <LightBox images={budgetImages} thumbnailHeight={90} />
            </div>

            <div className="post">
                <h3>Individuelle HR Prozesse</h3>
                <p>
                    Über das HR Portal MyAbacus können einerseits Standardprozesse andererseits aber auch auf Ihre Bedürfnisse zugeschnittene, individuelle Prozesse realisiert
                    werden. Beispiele für individuelle HR Prozesse sind z.B.:
                </p>
                <ul>
                    <li>Ausbildungsantrag für Mitarbeitende</li>
                    <li>Parkplatzantrag</li>
                    <li>Spezielle Lohnrunde</li>
                    <li>Anträge jeglicher Art (Ideen, ..)</li>
                </ul>
            </div>

            <div className="post">
                <h3>Spesen</h3>
                <p>
                    Geschäftliche Spesen können direkt in der Smartphone App AbaCliK oder im HR Portal MyAbacus erfasst werden. Zur einfachen Zuordnung der
                    Spesenherkunft ist es möglich, Spesen für verschiedene Kategorien zu erfassen. Es sind Spesen sowohl in Schweizer Franken als auch in
                    beliebigen anderen Währungen möglich. Zusätzlich kann erfasst werden, über welche Zahlungsart – Bar, Kreditkarte etc. – der Betrag bezahlt wurde.
                    Nach der Synchronisation mit der ERP Software stehen die Spesen als periodenunabhängige Vorerfassungen in der Abacus Lohnbuchhaltung zur
                    Verfügung und können medienbruchfrei beim nächsten Lohnlauf ausbezahlt werden
                </p>
            </div>

            <OtherLinks links={links} />
        </section>
    </Layout>
)

export default Prozessmanagement


const bewerberImages = [
    {
        index: 0,
        src: bewerber1,
        title: 'Bewerberstamm',
        description: 'Alle Angaben zu den Bewerbenden aus Sicht HR'
    },
    {
        index: 1,
        src: bewerber2,
        title: 'Bewerbercockpit',
        description: 'Übersichtliches Cockpit auf alle Vakanzen und Bewerbenden'
    },
    {
        index: 2,
        src: bewerber3,
        title: 'Bewerber aus Sicht des Portals',
        description: 'Sicht auf den Bewerbenden aus Sicht der Linie'
    },
]

const zeugnisImages = [
    {
        index: 0,
        src: zeugnis1,
        title: 'Meine Daten - Arbeitszeugnisse',
        description: 'Zeugnisse pro Mitarbeitenden'
    },
]

const magImages = [
    {
        index: 0,
        src: mag1,
        title: 'Meine Daten - Mitarbeitergespräche',
        description: 'Verschiedene Gespräche pro Mitarbeitenden im HR Portal'
    },
]

const dossierImages = [
    {
        index: 0,
        src: dossier1,
        title: 'Abacus Dossier aus Sicht HR',
        description: 'Personaldossier aus Sicht von HR'
    },
    {
        index: 1,
        src: dossier2,
        title: 'Meine Daten - Abacus Dossier',
        description: 'Abacus Dossier (Beispiel Lohnausweise) aus Sicht des Mitarbeitenden im Portal'
    },
    {
        index: 2,
        src: dossier3,
        title: 'Meine Daten - Abacus Dossier',
        description: 'Abacus Dossier (Beispiel Lohnausweise) aus Sicht des Mitarbeitenden im Portal geöffnet'
    },
]

const budgetImages = [
    {
        index: 0,
        src: budget1,
        title: 'Abacus Budget Cockpit',
        description: 'Das Abacus Budgetcockpit'
    },
    {
        index: 1,
        src: budget2,
        title: 'Abacus Budget Cockpit 2',
        description: 'Das Abacus Budgetcockpit (Summenbaum kann nach verschiedenen Kriterien definiert werden)'
    },
]

const links = [
    {
        name: 'Mitarbeiterportal',
        desc: 'Abacus Mitarbeiterportal',
        link: 'https://hr-portal.ch/mitarbeiterportal/'
    },
    {
        name: 'Bewerbermanagement',
        desc: 'Abacus Bewerbermanagement',
        link: 'https://hr-portal.ch/bewerbermanagement/'
    },
    {
        name: 'Personaldossier',
        desc: 'Abacus Personaldossier',
        link: 'https://hr-portal.ch/personalverwaltung/#personaldossier'
    }
]